import React from "react"

const CategoryPolicy: React.FC = () => {
  const categoriesData: {
    index?: string
    category: string
    example: string
    collected: string
  }[] = [
    {
      index: "A.",
      category: "Identifiers",
      example:
        "Contact details, such as real name, alias, postal address, telephone or mobile contact number, unique personal identifier, online identifier, Internet Protocol address, email address and account name",
      collected: "No",
    },
    {
      index: "B.",
      category:
        "Personal information categories listed in the California Customer Records statute",
      example:
        "Name, contact information, education, employment, employment history",
      collected: "Yes",
    },
    {
      index: "C.",
      category:
        "Protected classification characteristics under California or federal law",
      example: "Gender and date of birth",
      collected: "Yes",
    },
    {
      index: "D.",
      category: "Commercial information",
      example:
        "Transaction information, purchase history, financial details and payment information",
      collected: "No",
    },
    {
      index: "E.",
      category: "Biometric information",
      example: "Fingerprints and voiceprints",
      collected: "No",
    },
    {
      index: "F.",
      category: "Internet or other similar network activity",
      example:
        "Browsing history, search history, online behavior, interest data, and interactions with our and other websites, applications, systems and advertisements",
      collected: "No",
    },
    {
      index: "G.",
      category: "Geolocation data",
      example: "Device location",
      collected: "No",
    },
    {
      index: "H.",
      category:
        "Audio, electronic, visual, thermal, olfactory, or similar information",
      example:
        "Images and audio, video or call recordings created in connection with our business activities",
      collected: "No",
    },
    {
      index: "I.",
      category: "Professional or employment-related information",
      example:
        "Business contact details in order to provide you our services at a business level, job title as well as work history and professional qualifications if you apply for a job with us",
      collected: "Yes",
    },
    {
      index: "J.",
      category: "Education Information",
      example: "Student records and directory information",
      collected: "Yes",
    },
    {
      index: "K.",
      category: "Inferences drawn from other personal information",
      example:
        "Inferences drawn from any of the collected personal information listed above to create a profile or summary about, for example, an individual’s preferences and characteristics",
      collected: "No",
    },
  ]
  return (
    <div>
      {categoriesData.map((category, index) => (
        <div className="w-full mb-4" key={index}>
          <div
            className="w-full bg-greyDefault text-white p-3 font-avertastd-bold flex"
            style={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}
          >
            <div className="mr-1 whitespace-no-wrap">{category.index}</div>
            <p>{category.category}</p>
          </div>
          <div
            className="w-full bg-white pt-2 px-3"
            style={{ borderBottomLeftRadius: 8, borderBottomRightRadius: 8 }}
          >
            <p className="text-greyDefault font-averta-std-regular pb-2">
              {category.example}
            </p>
            <p className="text-black font-averta-std-regular pb-4">
              Collected:{" "}
              <span className="font-avertastd-bold">{category.collected}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  )
}

export default CategoryPolicy

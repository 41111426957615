import React from "react"
import { TextTypography } from "../SharedResources/Typography/TextTypography/TextTypography"
import {
  FontStyle,
  TextColor,
} from "../SharedResources/Typography/typography.service"
import CategoryPolicy from "./CategoryPolicy/CategoryPolicy"
import "./Policy.scss"
import FedRAMP from "../../images/fedRAMP.png"
import LockIcon from "../../images/lock.svg"
import IdCardIcon from "../../images/idCard.svg"
import FingerprintIcon from "../../images/fingerprint.svg"
import GlobeIcon from "../../images/globe.svg"
import DollarIcon from "../../images/dollarSign.svg"
import CircleQuestionIcon from "../../images/circleQuestion.svg"

interface DomePrivacyPolicyProps {
  updationDate?: string
  effectiveDate?: string
}

const DomePrivacyPolicy: React.FC<DomePrivacyPolicyProps> = () => {
  const followingData = [
    { logo: IdCardIcon, boldText: "Identifiers", text: "" },
    {
      logo: DollarIcon,
      boldText: "Financial & commercial",
      text: "information",
    },
    { logo: FingerprintIcon, boldText: "Biometric", text: "information" },
    {
      logo: GlobeIcon,
      boldText: "Internet",
      text: "or other similar network activity",
    },
    {
      logo: CircleQuestionIcon,
      boldText: "Inferences",
      text: "drawn from other personal information",
    },
  ]

  const DataCard = ({ data }) => (
    <div className="flex space-x-5">
      <img src={data.logo} alt="Logo" />
      <div>
        <TextTypography fontStyle={FontStyle.BOLD}>
          {data.boldText}{" "}
        </TextTypography>
        <TextTypography>{data.text}</TextTypography>
      </div>
    </div>
  )
  return (
    <main className={"w-full body-policy"}>
      <section className="flex justify-center bg-blueShade1">
        <article className="flex flex-col max-w-700 xl-mobile:px-8 mobile-screen">
          <TextTypography
            className="my-12 text-4xl sm:text-6xl text-center"
            fontStyle={FontStyle.BOLD}
          >
            Dome Compass, Inc.
          </TextTypography>
          <div className="flex flex-col space-y-5 mb-12">
            <TextTypography
              className="text-2xl sm:text-3xl"
              fontStyle={FontStyle.BOLD}
            >
              Never Collected
            </TextTypography>
            <TextTypography>
              Dome values your privacy. The following information is never
              collected from you while using Dome.
            </TextTypography>
            <div className="p-5 border-2 border-gray-400 bg-white w-full rounded-2xl">
              <div className="flex flex-col items-center space-y-3 mb-5">
                <img src={LockIcon} alt="Lock Icon" />
                <TextTypography
                  className="text-lg sm:text-xl"
                  fontStyle={FontStyle.BOLD}
                >
                  Not Collected by App
                </TextTypography>
              </div>
              <TextTypography>
                The following data is never collected by the application:
              </TextTypography>

              <div className="flex flex-col space-y-5 mt-5">
                {followingData.map((data, index) => (
                  <DataCard data={data} key={index} />
                ))}
              </div>
            </div>
          </div>
          <div className="flex flex-col items-center mb-6">
            <img src={FedRAMP} alt="fedRAMP" className="w-40 mb-3 rounded-16" />
            <TextTypography
              className="text-10 font-averta-std-regular text-center px-3"
              color={TextColor.SECONDARY}
            >
              *Product is hosted on the AWS FedRAMP compliant server, but has
              not been certified by a third party.
            </TextTypography>
          </div>
          <div>
            <div className="text-center">
              <strong>
                <span style={{ fontSize: 26 }}>
                  <span className="title-policy">PRIVACY NOTICE</span>
                </span>
              </strong>
            </div>
            <div className="text-center">
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ fontSize: 15 }}>
                  <span className="subtitle-policy text-14">
                    Last updated October 12, 2022
                  </span>
                </span>
              </span>
            </div>
            <div>
              <br />
            </div>
            <div>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <strong>
                  <span style={{ fontSize: 15 }}>
                    <span className="subtitle-policy">
                      Executive summary of policy
                    </span>
                  </span>
                </strong>
              </span>
              <div
                style={{
                  lineHeight: "1.5",
                  marginBottom: 16,
                }}
              >
                <span
                  className="body-text-policy"
                  style={{
                    fontSize: 15,
                    color: "rgb(89, 89, 89)",
                  }}
                >
                  We have collected the following categories of personal
                  information in the past twelve (12) months:
                </span>
              </div>
              <CategoryPolicy />
            </div>
            <div style={{ lineHeight: "1.5", marginTop: 16 }}>
              <span style={{ color: "rgb(127, 127, 127)" }}>
                <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                  <span className="body-text-policy">
                    Thank you for choosing to be part of our community at Dome
                    Compass, Inc. (&quot;
                    <strong>Company</strong>,&quot; &quot;<strong>we</strong>
                    ,&quot; &quot;
                    <strong>us</strong>,&quot; or &quot;<strong>our</strong>
                    &quot;). We are committed to protecting your personal
                    information and your right to privacy. If you have any
                    questions or concerns about this privacy notice or our
                    practices with regard to your personal information, please
                    contact us at{" "}
                    <a
                      href={`mailto:privacy@domecompass.com`}
                      target={"_blank"}
                      rel={"noreferrer noopener"}
                      className="text-2xl link-policy"
                    >
                      privacy@domecompass.com
                    </a>
                  </span>
                </span>
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <br />
              </span>
            </div>
            <div style={{ lineHeight: "1.5" }}>
              <span style={{ fontSize: 15 }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span className="body-text-policy">
                    This privacy notice describes how we might use your
                    information if you:
                  </span>
                </span>
              </span>
            </div>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="body-text-policy">
                    Visit our website at{" "}
                    <a
                      href="https://domecompass.com"
                      target="_blank"
                      className="link-policy"
                      rel="noreferrer"
                    >
                      https://domecompass.com
                    </a>
                  </span>
                </span>
              </li>
            </ul>
            <ul>
              <li style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span className="body-text-policy">
                    Download and use our mobile application — DOMECompass
                  </span>
                </span>
              </li>
            </ul>
            <div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        Engage with us in other related ways ― including any
                        sales, marketing, or events
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(127, 127, 127)" }}>
                  <span className="body-text-policy">
                    In this privacy notice, if we refer to:
                  </span>
                </span>
              </div>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      &quot;<strong>Website</strong>,&quot; we are referring to
                      any website of ours that references or links to this
                      policy
                    </span>
                  </span>
                </li>
              </ul>
              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      &quot;<strong>App</strong>,&quot; we are referring to any
                      application of ours that references or links to this
                      policy, including any listed above
                    </span>
                  </span>
                </li>
              </ul>

              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        &quot;<strong>Services</strong>,&quot; we are referring
                        to our Website,
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span className="body-text-policy">&nbsp;App,</span>
                          </span>
                        </span>
                        <span style={{ fontSize: 15 }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span className="body-text-policy">
                              &nbsp;and other related services, including any
                              sales, marketing, or events
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </li>
              </ul>

              <ul>
                <li style={{ lineHeight: "1.5" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        The purpose of this privacy notice is to explain to you
                        in the clearest way possible what information we
                        collect, how we use it, and what rights you have in
                        relation to it. If there are any terms in this privacy
                        notice that you do not agree with, please discontinue
                        use of our Services immediately.
                      </span>
                    </span>
                  </span>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <br />
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)" }}>
                      <span className="body-text-policy">
                        <strong>
                          Please read this privacy notice carefully, as it will
                          help you understand what we do with the information
                          that we collect.
                        </strong>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <br />
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(0, 0, 0)" }}>
                      <strong>
                        <span className="heading-one-policy">
                          TABLE OF CONTENTS
                        </span>
                      </strong>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15 }}>
                  <br />
                </span>
              </div>
              <ul className="table-of-contents-list-styles">
                <li>
                  <a className="link-policy" href="#infocollect">
                    WHAT INFORMATION DO WE COLLECT?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    HOW DO WE USE YOUR INFORMATION?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    WILL YOUR INFORMATION BE SHARED WITH ANYONE?{" "}
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    HOW LONG DO WE KEEP YOUR INFORMATION?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    HOW DO WE KEEP YOUR INFORMATION SAFE?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    DO WE COLLECT INFORMATION FROM MINORS?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    WHAT ARE YOUR PRIVACY RIGHTS?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    CONTROLS FOR DO-NOT-TRACK FEATURES
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    DO WE MAKE UPDATES TO THIS NOTICE?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
                  </a>
                </li>
                <li>
                  <a className="link-policy" href="#infocollect">
                    HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT
                    FROM YOU?
                  </a>
                </li>
              </ul>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div id="infocollect" style={{ lineHeight: "1.5" }}>
                <span style={{ color: "rgb(127, 127, 127)" }}>
                  <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                    <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                      <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                        <span id="control" style={{ color: "rgb(0, 0, 0)" }}>
                          <strong>
                            <span className="heading-one-policy">
                              1. WHAT INFORMATION DO WE COLLECT?
                            </span>
                          </strong>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span
                  className="heading-two-policy"
                  style={{ color: "rgb(0, 0, 0)" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>
                      <u>
                        <br />
                      </u>
                    </strong>
                    <strong>Personal information you disclose to us</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span className="body-text-policy">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              <strong>
                                <em>In Short:&nbsp;</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="body-text-policy">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                We collect personal information that you provide
                                to us.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      We collect personal information that you voluntarily
                      provide to us when you{" "}
                      <span style={{ fontSize: 15 }}></span>
                      register on the&nbsp;
                    </span>
                    <span style={{ fontSize: 15 }}>
                      <span className="body-text-policy">Services,</span>
                      <span className="body-text-policy">
                        <span style={{ fontSize: 15 }}></span>
                      </span>
                      <span className="body-text-policy">
                        express an interest in obtaining information about us or
                        our products and Services, when you participate in
                        activities on the{" "}
                        <span style={{ fontSize: 15 }}>Services</span>
                        (such as by posting messages in our online forums or
                        entering competitions, contests or giveaways)
                      </span>
                    </span>
                    <span className="body-text-policy">
                      &nbsp;or otherwise when you contact us.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      The personal information that we collect depends on the
                      context of your interactions with us and the{" "}
                      <span style={{ fontSize: 15 }}>
                        <span className="body-text-policy">Services</span>, the
                        choices you make and the products and features you use.
                        The personal information we collect may include the
                        following:
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      <strong>Personal Information Provided by You.</strong> We
                      collect{" "}
                      <span style={{ fontSize: 15 }}>
                        <span className="body-text-policy">names;&nbsp;</span>
                        <span className="body-text-policy">
                          phone numbers;&nbsp;
                        </span>
                        <span className="body-text-policy">
                          email addresses;&nbsp;
                        </span>
                        <span className="body-text-policy">
                          job titles;&nbsp;
                        </span>
                        <span className="body-text-policy">
                          passwords;&nbsp;
                        </span>
                        <span className="body-text-policy">
                          contact preferences;&nbsp;
                        </span>
                        <span className="body-text-policy">
                          and other similar information.
                        </span>
                        <span className="body-text-policy"></span>
                      </span>
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      All personal information that you provide to us must be
                      true, complete and accurate, and you must notify us of any
                      changes to such personal information.
                    </span>
                  </span>
                </span>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span
                  className="heading-two-policy"
                  style={{ color: "rgb(0, 0, 0)" }}
                >
                  <span style={{ fontSize: 15 }}>
                    <strong>
                      <u>
                        <br />
                      </u>
                    </strong>
                    <strong>Information collected through our App</strong>
                  </span>
                </span>
              </div>
              <div>
                <div>
                  <br />
                </div>
                <div style={{ lineHeight: "1.5" }}>
                  <span style={{ color: "rgb(127, 127, 127)" }}>
                    <span style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}>
                      <span className="body-text-policy">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              <strong>
                                <em>In Short:&nbsp;</em>
                              </strong>
                            </span>
                          </span>
                        </span>
                      </span>
                      <span className="body-text-policy">
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              <strong>
                                <em>&nbsp;</em>
                              </strong>
                              <em>
                                We collect information regarding your{" "}
                                <span
                                  className="body-text-policy"
                                  style={{ fontSize: 15 }}
                                >
                                  push notifications,
                                </span>{" "}
                                when you use our App.
                              </em>
                            </span>
                          </span>
                        </span>
                      </span>
                    </span>
                  </span>
                </div>
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <br />
              </div>
              <div style={{ lineHeight: "1.5" }}>
                <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                  <span style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}>
                    <span className="body-text-policy">
                      If you use our App, we also collect the following
                      information:
                    </span>
                  </span>
                </span>
                <div>
                  <div>
                    <ul>
                      <li style={{ lineHeight: "1.5" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              <strong>Push Notifications.&nbsp;</strong>We may
                              request to send you push notifications regarding
                              your account or certain features of the App. If
                              you wish to opt-out from receiving these types of
                              communications, you may turn them off in your
                              device&apos;s settings.
                            </span>
                          </span>
                        </span>
                      </li>
                    </ul>
                    <div>
                      <div style={{ lineHeight: "1.5", marginTop: 12 }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              This information is primarily needed to maintain
                              the security and operation of our App, for
                              troubleshooting and for our internal analytics and
                              reporting purposes.
                            </span>
                          </span>
                        </span>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <span
                          className="heading-two-policy"
                          style={{ color: "rgb(0, 0, 0)" }}
                        >
                          <span style={{ fontSize: 15 }}>
                            <strong>
                              <u>
                                <br />
                              </u>
                            </strong>
                            <strong>
                              Information collected from other sources
                            </strong>
                          </span>
                        </span>
                      </div>
                      <div>
                        <div>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                            >
                              <span className="body-text-policy">
                                <strong>
                                  <em>In Short: &nbsp;</em>
                                </strong>
                                <em>
                                  We may collect limited data from public
                                  databases, marketing partners, and other
                                  outside sources.
                                </em>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span className="body-text-policy">
                            In order to enhance our ability to provide relevant
                            marketing, offers and services to you and update our
                            records, we may obtain information about you from
                            other sources, such as public databases, joint
                            marketing partners, affiliate programs, data
                            providers, as well as from other third parties. This
                            information includes mailing addresses, job titles,
                            email addresses, phone numbers, intent data (or user
                            behavior data), Internet Protocol (IP) addresses,
                            social media profiles, social media URLs and custom
                            profiles, for purposes of targeted advertising and
                            event promotion.
                          </span>
                        </span>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div id="infouse" style={{ lineHeight: "1.5" }}>
                        <span style={{ color: "rgb(127, 127, 127)" }}>
                          <span
                            style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                          >
                            <span
                              style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                            >
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  id="control"
                                  style={{ color: "rgb(0, 0, 0)" }}
                                >
                                  <strong>
                                    <span className="heading-one-policy">
                                      2. HOW DO WE USE YOUR INFORMATION?
                                    </span>
                                  </strong>
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </div>
                      <div>
                        <div style={{ lineHeight: "1.5" }}>
                          <br />
                        </div>
                        <div style={{ lineHeight: "1.5" }}>
                          <span style={{ color: "rgb(127, 127, 127)" }}>
                            <span
                              style={{ color: "rgb(89, 89, 89)", fontSize: 15 }}
                            >
                              <span className="body-text-policy">
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span className="body-text-policy">
                                      <strong>
                                        <em>In Short: &nbsp;</em>
                                      </strong>
                                      <em>
                                        We process your information for purposes
                                        based on legitimate business interests,
                                        the fulfillment of our contract with
                                        you, compliance with our legal
                                        obligations, and/or your consent.
                                      </em>
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </span>
                          </span>
                        </div>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              We use personal information collected via our for
                              a variety of business purposes described below. We
                              process your personal information for these
                              purposes in reliance on our legitimate business
                              interests, in order to enter into or perform a
                              contract with you, with your consent, and/or for
                              compliance with our legal obligations. We indicate
                              the specific processing grounds we rely on next to
                              each purpose listed below.
                            </span>
                          </span>
                        </span>
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <br />
                      </div>
                      <div style={{ lineHeight: "1.5" }}>
                        <span
                          style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                        >
                          <span
                            style={{ fontSize: 15, color: "rgb(89, 89, 89)" }}
                          >
                            <span className="body-text-policy">
                              We use the information we collect or receive:
                            </span>
                          </span>
                        </span>
                        <div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>
                                      To send administrative information to
                                      you.&nbsp;
                                    </strong>
                                    We may use your personal information to send
                                    you product, service and new feature
                                    information and/or information about changes
                                    to our terms, conditions, and policies.
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div></div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>
                                      To protect our Services.&nbsp;
                                    </strong>
                                    We may use your information as part of our
                                    efforts to keep our Services safe and secure
                                    (for example, for fraud monitoring and
                                    prevention).
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div></div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>
                                      To enforce our terms, conditions and
                                      policies for business purposes, to comply
                                      with legal and regulatory requirements or
                                      in connection with our contract.
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <div></div>
                          <ul>
                            <li style={{ lineHeight: "1.5" }}>
                              <span style={{ fontSize: 15 }}>
                                <span style={{ color: "rgb(89, 89, 89)" }}>
                                  <span style={{ color: "rgb(89, 89, 89)" }}>
                                    <span className="body-text-policy">
                                      <strong>
                                        To respond to legal requests and prevent
                                        harm.&nbsp;
                                      </strong>
                                      If we receive a subpoena or other legal
                                      request, we may need to inspect the data
                                      we hold to determine how to respond.
                                    </span>
                                  </span>
                                </span>
                              </span>
                            </li>
                          </ul>
                          <p style={{ fontSize: 15 }}></p>
                          <p style={{ fontSize: 15 }}></p>
                          <p style={{ fontSize: 15 }}></p>
                          <p style={{ fontSize: 15 }}></p>
                          <div>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>
                                      For other business purposes.
                                    </strong>
                                  </span>
                                  <span className="body-text-policy">
                                    &nbsp;We may use your information for other
                                    business purposes, such as data analysis,
                                    identifying usage trends, determining the
                                    effectiveness of our promotional campaigns
                                    and to evaluate and improve our{" "}
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            Services
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                    , products, marketing and your experience.
                                    We may use and store this information in
                                    aggregated and anonymized form so that it is
                                    not associated with individual end users and
                                    does not include personal information.
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                              <br />
                            </div>
                            <div id="infoshare" style={{ lineHeight: "1.5" }}>
                              <span style={{ color: "rgb(127, 127, 127)" }}>
                                <span
                                  style={{
                                    color: "rgb(89, 89, 89)",
                                    fontSize: 15,
                                  }}
                                >
                                  <span
                                    id="control"
                                    style={{
                                      color: "rgb(0, 0, 0)",
                                    }}
                                  >
                                    <strong>
                                      <span className="heading-one-policy">
                                        3. WILL YOUR INFORMATION BE SHARED WITH
                                        ANYONE?
                                      </span>
                                    </strong>
                                  </span>
                                </span>
                              </span>
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                              <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span className="body-text-policy">
                                  <strong>
                                    <em>In Short:</em>
                                  </strong>
                                  <em>
                                    &nbsp; We only share information with your
                                    consent, to comply with laws, to provide you
                                    with services, to protect your rights, or to
                                    fulfill business obligations.
                                  </em>
                                </span>
                              </span>
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                              <br />
                            </div>
                            <div style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span className="body-text-policy">
                                  We may process or share your data that we hold
                                  based on the following legal basis:
                                </span>
                              </span>
                            </div>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>Consent:</strong> We may process
                                    your data if you have given us specific
                                    consent to use your personal information for
                                    a specific purpose.
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span className="body-text-policy">
                                      <strong>Legitimate Interests:</strong> We
                                      may process your data when it is
                                      reasonably necessary to achieve our
                                      legitimate business interests.
                                    </span>
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>Performance of a Contract:</strong>{" "}
                                    Where we have entered into a contract with
                                    you, we may process your personal
                                    information to fulfill the terms of our
                                    contract.
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>Legal Obligations:</strong> We may
                                    disclose your information where we are
                                    legally required to do so in order to comply
                                    with applicable law, governmental requests,
                                    a judicial proceeding, court order, or legal
                                    process, such as in response to a court
                                    order or a subpoena (including in response
                                    to public authorities to meet national
                                    security or law enforcement requirements).
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>Vital Interests:</strong> We may
                                    disclose your information where we believe
                                    it is necessary to investigate, prevent, or
                                    take action regarding potential violations
                                    of our policies, suspected fraud, situations
                                    involving potential threats to the safety of
                                    any person and illegal activities, or as
                                    evidence in litigation in which we are
                                    involved.
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div style={{ lineHeight: "1.5" }}>
                              <span
                                style={{
                                  fontSize: 15,
                                  color: "rgb(89, 89, 89)",
                                }}
                              >
                                <span className="body-text-policy">
                                  More specifically, we may need to process your
                                  data or share your personal information in the
                                  following situations:
                                </span>
                              </span>
                            </div>
                            <ul>
                              <li style={{ lineHeight: "1.5" }}>
                                <span
                                  style={{
                                    fontSize: 15,
                                    color: "rgb(89, 89, 89)",
                                  }}
                                >
                                  <span className="body-text-policy">
                                    <strong>Business Transfers.</strong> We may
                                    share or transfer your information in
                                    connection with, or during negotiations of,
                                    any merger, sale of company assets,
                                    financing, or acquisition of all or a
                                    portion of our business to another company.
                                  </span>
                                </span>
                              </li>
                            </ul>
                            <div>
                              <ul>
                                <li
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <strong>Other Users.&nbsp;</strong>
                                        When you share personal information{" "}
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy"></span>
                                          </span>
                                        </span>
                                        (for example, by posting comments,
                                        contributions or other content to the{" "}
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span className="body-text-policy">
                                                    Services
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        ) or otherwise interact with public
                                        areas of the{" "}
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span className="body-text-policy">
                                                    Services
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        , such personal information may be
                                        viewed by all users and may be publicly
                                        made available outside the{" "}
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span className="body-text-policy">
                                                    Services
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>{" "}
                                        in perpetuity. Similarly, other users
                                        will be able to view descriptions of
                                        your activity, communicate with you
                                        within our{" "}
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span
                                                  style={{
                                                    color: "rgb(89, 89, 89)",
                                                  }}
                                                >
                                                  <span className="body-text-policy">
                                                    Services
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        , and view your profile.
                                      </span>
                                    </span>
                                  </span>
                                </li>
                              </ul>
                              <div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  id="inforetain"
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(127, 127, 127)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: 15,
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            id="control"
                                            style={{
                                              color: "rgb(0, 0, 0)",
                                            }}
                                          >
                                            <strong>
                                              <span className="heading-one-policy">
                                                4. HOW LONG DO WE KEEP YOUR
                                                INFORMATION?
                                              </span>
                                            </strong>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <strong>
                                          <em>In Short:&nbsp;</em>
                                        </strong>
                                        <em>
                                          &nbsp;We keep your information for as
                                          long as necessary to fulfill the
                                          purposes outlined in this privacy
                                          notice unless otherwise required by
                                          law.
                                        </em>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        We will only keep your personal
                                        information for as long as it is
                                        necessary for the purposes set out in
                                        this privacy notice, unless a longer
                                        retention period is required or
                                        permitted by law (such as tax,
                                        accounting or other legal requirements).
                                        No purpose in this notice will require
                                        us keeping your personal information for
                                        longer than the period of time in which
                                        users have an account with us.
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        When we have no ongoing legitimate
                                        business need to process your personal
                                        information, we will either delete or
                                        anonymize such information, or, if this
                                        is not possible (for example, because
                                        your personal information has been
                                        stored in backup archives), then we will
                                        securely store your personal information
                                        and isolate it from any further
                                        processing until deletion is possible.
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        ></span>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  id="infosafe"
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(127, 127, 127)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: 15,
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            id="control"
                                            style={{
                                              color: "rgb(0, 0, 0)",
                                            }}
                                          >
                                            <strong>
                                              <span className="heading-one-policy">
                                                5. HOW DO WE KEEP YOUR
                                                INFORMATION SAFE?
                                              </span>
                                            </strong>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <strong>
                                          <em>In Short:&nbsp;</em>
                                        </strong>
                                        <em>
                                          &nbsp;We aim to protect your personal
                                          information through a system of
                                          organizational and technical security
                                          measures.
                                        </em>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        We have implemented appropriate
                                        technical and organizational security
                                        measures designed to protect the
                                        security of any personal information we
                                        process. However, despite our safeguards
                                        and efforts to secure your information,
                                        no electronic transmission over the
                                        Internet or information storage
                                        technology can be guaranteed to be 100%
                                        secure, so we cannot promise or
                                        guarantee that hackers, cybercriminals,
                                        or other unauthorized third parties will
                                        not be able to defeat our security, and
                                        improperly collect, access, steal, or
                                        modify your information. Although we
                                        will do our best to protect your
                                        personal information, transmission of
                                        personal information to and from our{" "}
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15,
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            <span
                                              style={{
                                                fontSize: 15,
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="body-text-policy">
                                                        Services
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>{" "}
                                        is at your own risk. You should only
                                        access the{" "}
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15,
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            <span
                                              style={{
                                                fontSize: 15,
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="body-text-policy">
                                                        Services
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>{" "}
                                        within a secure environment.
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  id="infominors"
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(127, 127, 127)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: 15,
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            id="control"
                                            style={{
                                              color: "rgb(0, 0, 0)",
                                            }}
                                          >
                                            <strong>
                                              <span className="heading-one-policy">
                                                6. DO WE COLLECT INFORMATION
                                                FROM MINORS?
                                              </span>
                                            </strong>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <strong>
                                          <em>In Short:</em>
                                        </strong>
                                        <em>
                                          &nbsp; We do not knowingly collect
                                          data from or market to children under
                                          18 years of age.
                                        </em>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        We do not knowingly solicit data from or
                                        market to children under 18 years of
                                        age. By using the{" "}
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15,
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            <span
                                              style={{
                                                fontSize: 15,
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="body-text-policy">
                                                        Services
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        , you represent that you are at least 18
                                        or that you are the parent or guardian
                                        of such a minor and consent to such
                                        minor dependent’s use of the{" "}
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15,
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            <span
                                              style={{
                                                fontSize: 15,
                                              }}
                                            >
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="body-text-policy">
                                                        Services
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        . If we learn that personal information
                                        from users less than 18 years of age has
                                        been collected, we will deactivate the
                                        account and take reasonable measures to
                                        promptly delete such data from our
                                        records. If you become aware of any data
                                        we may have collected from children
                                        under age 18, please contact us at{" "}
                                        <a
                                          href={`mailto:privacy@domecompass.com`}
                                          target={"_blank"}
                                          rel={"noreferrer noopener"}
                                          className="text-2xl link-policy"
                                        >
                                          privacy@domecompass.com
                                        </a>
                                        .
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  id="privacyrights"
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(127, 127, 127)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(89, 89, 89)",
                                        fontSize: 15,
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            id="control"
                                            style={{
                                              color: "rgb(0, 0, 0)",
                                            }}
                                          >
                                            <strong>
                                              <span className="heading-one-policy">
                                                7. WHAT ARE YOUR PRIVACY RIGHTS?
                                              </span>
                                            </strong>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <strong>
                                          <em>In Short:</em>
                                        </strong>
                                        <em>
                                          &nbsp;{" "}
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                              }}
                                            >
                                              <span className="body-text-policy">
                                                <em></em>
                                              </span>
                                            </span>
                                          </span>
                                          You may review, change, or terminate
                                          your account at any time.
                                        </em>
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    &nbsp;
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        If you are a resident in the EEA or UK
                                        and you believe we are unlawfully
                                        processing your personal information,
                                        you also have the right to complain to
                                        your local data protection supervisory
                                        authority. You can find their contact
                                        details here:{" "}
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  color: "rgb(48, 48, 241)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <a
                                                    className="link-policy"
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                    href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                    rel="noopener noreferrer"
                                                    target="_blank"
                                                  >
                                                    https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm
                                                  </a>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        .
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        If you are a resident in Switzerland,
                                        the contact details for the data
                                        protection authorities are available
                                        here:{" "}
                                        <span
                                          style={{
                                            fontSize: 15,
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  color: "rgb(48, 48, 241)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <a
                                                      className="link-policy"
                                                      href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                      rel="noopener noreferrer"
                                                      target="_blank"
                                                    >
                                                      https://www.edoeb.admin.ch/edoeb/en/home.html
                                                    </a>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                        .
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <br />
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    style={{
                                      fontSize: 15,
                                      color: "rgb(89, 89, 89)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        If you have questions or comments about
                                        your privacy rights, you may email us at{" "}
                                        <a
                                          href={`mailto:privacy@domecompass.com`}
                                          target={"_blank"}
                                          rel={"noreferrer noopener"}
                                          className="text-2xl link-policy"
                                        >
                                          privacy@domecompass.com
                                        </a>
                                        .
                                      </span>
                                    </span>
                                  </span>
                                </div>
                                <div
                                  style={{
                                    lineHeight: "1.5",
                                  }}
                                >
                                  <span
                                    className="heading-two-policy"
                                    style={{
                                      color: "rgb(0, 0, 0)",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <strong>
                                        <u>
                                          <br />
                                        </u>
                                      </strong>
                                      <strong>Account Information</strong>
                                    </span>
                                  </span>
                                </div>
                                <div>
                                  <div>
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(127, 127, 127)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15,
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span className="body-text-policy">
                                                If you would at any time like to
                                                review or change the information
                                                in your account or terminate
                                                your account, you can:
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(127, 127, 127)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15,
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  Log in to your account
                                                  settings and update your user
                                                  account.
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          Upon your request to terminate your
                                          account, we will deactivate or delete
                                          your account and information from our
                                          active databases. However, we may
                                          retain some information in our files
                                          to prevent fraud, troubleshoot
                                          problems, assist with any
                                          investigations, enforce our Terms of
                                          Use and/or comply with applicable
                                          legal requirements.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <strong>
                                            <u>
                                              Opting out of email marketing:
                                            </u>
                                          </strong>{" "}
                                          You can unsubscribe from our marketing
                                          email list at any time by clicking on
                                          the unsubscribe link in the emails
                                          that we send or by contacting us using
                                          the details provided below. You will
                                          then be removed from the marketing
                                          email list — however, we may still
                                          communicate with you, for example to
                                          send you service-related emails that
                                          are necessary for the administration
                                          and use of your account, to respond to
                                          service requests, or for other
                                          non-marketing purposes. To otherwise
                                          opt-out, you may:
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        Access your account settings and update
                                        your preferences.
                                      </span>
                                    </li>
                                  </ul>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    id="DNT"
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(127, 127, 127)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              id="control"
                                              style={{
                                                color: "rgb(0, 0, 0)",
                                              }}
                                            >
                                              <strong>
                                                <span className="heading-one-policy">
                                                  8. CONTROLS FOR DO-NOT-TRACK
                                                  FEATURES
                                                </span>
                                              </strong>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          Most web browsers and some mobile
                                          operating systems and mobile
                                          applications include a Do-Not-Track
                                          (&quot;DNT&quot;) feature or setting
                                          you can activate to signal your
                                          privacy preference not to have data
                                          about your online browsing activities
                                          monitored and collected. At this stage
                                          no uniform technology standard for
                                          recognizing and implementing DNT
                                          signals has been finalized. As such,
                                          we do not currently respond to DNT
                                          browser signals or any other mechanism
                                          that automatically communicates your
                                          choice not to be tracked online. If a
                                          standard for online tracking is
                                          adopted that we must follow in the
                                          future, we will inform you about that
                                          practice in a revised version of this
                                          privacy notice.
                                        </span>
                                      </span>
                                      &nbsp;
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    id="caresidents"
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(127, 127, 127)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              id="control"
                                              style={{
                                                color: "rgb(0, 0, 0)",
                                              }}
                                            >
                                              <strong>
                                                <span className="heading-one-policy">
                                                  9. DO CALIFORNIA RESIDENTS
                                                  HAVE SPECIFIC PRIVACY RIGHTS?
                                                </span>
                                              </strong>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <strong>
                                            <em>In Short:&nbsp;</em>
                                          </strong>
                                          <em>
                                            &nbsp;Yes, if you are a resident of
                                            California, you are granted specific
                                            rights regarding access to your
                                            personal information.
                                          </em>
                                        </span>
                                      </span>
                                    </span>
                                  </div>

                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          California Civil Code Section 1798.83,
                                          also known as the &quot;Shine The
                                          Light&quot; law, permits our users who
                                          are California residents to request
                                          and obtain from us, once a year and
                                          free of charge, information about
                                          categories of personal information (if
                                          any) we disclosed to third parties for
                                          direct marketing purposes and the
                                          names and addresses of all third
                                          parties with which we shared personal
                                          information in the immediately
                                          preceding calendar year. If you are a
                                          California resident and would like to
                                          make such a request, please submit
                                          your request in writing to us using
                                          the contact information provided
                                          below.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          If you are under 18 years of age,
                                          reside in California, and have a
                                          registered account with{" "}
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15,
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="body-text-policy">
                                                        a Service
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          , you have the right to request
                                          removal of unwanted data that you
                                          publicly post on the{" "}
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15,
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="body-text-policy">
                                                        Services
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          . To request removal of such data,
                                          please contact us using the contact
                                          information provided below, and
                                          include the email address associated
                                          with your account and a statement that
                                          you reside in California. We will make
                                          sure the data is not publicly
                                          displayed on the{" "}
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15,
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              <span
                                                style={{
                                                  color: "rgb(89, 89, 89)",
                                                  fontSize: 15,
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  <span
                                                    style={{
                                                      fontSize: 15,
                                                    }}
                                                  >
                                                    <span
                                                      style={{
                                                        color:
                                                          "rgb(89, 89, 89)",
                                                      }}
                                                    >
                                                      <span className="body-text-policy">
                                                        Services
                                                      </span>
                                                    </span>
                                                  </span>
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                          , but please be aware that the data
                                          may not be completely or
                                          comprehensively removed from all our
                                          systems (e.g. backups, etc.).
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      className="heading-two-policy"
                                      style={{
                                        color: "rgb(0, 0, 0)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        <strong>
                                          <u>
                                            <br />
                                          </u>
                                        </strong>
                                        <strong>CCPA Privacy Notice</strong>
                                      </span>
                                    </span>
                                  </div>
                                  <div>
                                    <div>
                                      <br />
                                    </div>
                                    <div
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(127, 127, 127)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15,
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span
                                                style={{
                                                  fontSize: 15,
                                                  color: "rgb(89, 89, 89)",
                                                }}
                                              >
                                                <span className="body-text-policy">
                                                  The California Code of
                                                  Regulations defines a
                                                  &quot;resident&quot; as:
                                                </span>
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </div>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                      marginLeft: 20,
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          (1) every individual who is in the
                                          State of California for other than a
                                          temporary or transitory purpose and
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                      marginLeft: 20,
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          (2) every individual who is domiciled
                                          in the State of California who is
                                          outside the State of California for a
                                          temporary or transitory purpose
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          All other individuals are defined as
                                          &quot;non-residents.&quot;
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          If this definition of
                                          &quot;resident&quot; applies to you,
                                          we must adhere to certain rights and
                                          obligations regarding your personal
                                          information.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  {/* <div
                                                                                                        style={{
                                                                                                            lineHeight:
                                                                                                                '1.5',
                                                                                                        }}
                                                                                                    ></div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            lineHeight:
                                                                                                                '1.5',
                                                                                                        }}
                                                                                                    >
                                                                                                        <br />
                                                                                                    </div> */}
                                  {/* <div
                                                                                                        style={{
                                                                                                            lineHeight:
                                                                                                                '1.5',
                                                                                                        }}
                                                                                                    ></div>
                                                                                                    <div
                                                                                                        style={{
                                                                                                            lineHeight:
                                                                                                                '1.5',
                                                                                                        }}
                                                                                                    >
                                                                                                        <br />
                                                                                                    </div> */}

                                  {/* <div
                                                                                                        style={{
                                                                                                            lineHeight:
                                                                                                                '1.5',
                                                                                                        }}
                                                                                                    >
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                    'rgb(89, 89, 89)',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span className="body-text-policy">
                                                                                                                    We
                                                                                                                    may
                                                                                                                    also
                                                                                                                    collect
                                                                                                                    other
                                                                                                                    personal
                                                                                                                    information
                                                                                                                    outside
                                                                                                                    of
                                                                                                                    these
                                                                                                                    categories
                                                                                                                    instances
                                                                                                                    where
                                                                                                                    you
                                                                                                                    interact
                                                                                                                    with
                                                                                                                    us
                                                                                                                    in-person,
                                                                                                                    online,
                                                                                                                    or
                                                                                                                    by
                                                                                                                    phone
                                                                                                                    or
                                                                                                                    mail
                                                                                                                    in
                                                                                                                    the
                                                                                                                    context
                                                                                                                    of:
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color:
                                                                                                                                'rgb(89, 89, 89)',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span className="body-text-policy">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                                        fontSize: 15,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span className="body-text-policy"></span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        <li
                                                                                                            style={{
                                                                                                                lineHeight:
                                                                                                                    '1.5',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="body-text-policy">
                                                                                                                        Receiving
                                                                                                                        help
                                                                                                                        through
                                                                                                                        our
                                                                                                                        customer
                                                                                                                        support
                                                                                                                        channels;
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="body-text-policy">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color:
                                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span className="body-text-policy">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                'rgb(89, 89, 89)',
                                                                                                                                            fontSize: 15,
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="body-text-policy">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span className="body-text-policy">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span className="body-text-policy"></span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </ul>
                                                                                                    <div>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                    'rgb(89, 89, 89)',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span className="body-text-policy">
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color:
                                                                                                                                'rgb(89, 89, 89)',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span className="body-text-policy">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                                        fontSize: 15,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span className="body-text-policy"></span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        <li
                                                                                                            style={{
                                                                                                                lineHeight:
                                                                                                                    '1.5',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="body-text-policy">
                                                                                                                        Participation
                                                                                                                        in
                                                                                                                        customer
                                                                                                                        surveys
                                                                                                                        or
                                                                                                                        contests;
                                                                                                                        and
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="body-text-policy">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color:
                                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span className="body-text-policy">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                'rgb(89, 89, 89)',
                                                                                                                                            fontSize: 15,
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="body-text-policy">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span className="body-text-policy">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span className="body-text-policy"></span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </ul> */}
                                  {/* <div>
                                                                                                        <span
                                                                                                            style={{
                                                                                                                fontSize: 15,
                                                                                                                color:
                                                                                                                    'rgb(89, 89, 89)',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span className="body-text-policy">
                                                                                                                    <span
                                                                                                                        style={{
                                                                                                                            fontSize: 15,
                                                                                                                            color:
                                                                                                                                'rgb(89, 89, 89)',
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span className="body-text-policy">
                                                                                                                                <span
                                                                                                                                    style={{
                                                                                                                                        color:
                                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                                        fontSize: 15,
                                                                                                                                    }}
                                                                                                                                >
                                                                                                                                    <span className="body-text-policy"></span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <ul>
                                                                                                        <li
                                                                                                            style={{
                                                                                                                lineHeight:
                                                                                                                    '1.5',
                                                                                                            }}
                                                                                                        >
                                                                                                            <span
                                                                                                                style={{
                                                                                                                    fontSize: 15,
                                                                                                                    color:
                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="body-text-policy">
                                                                                                                        Facilitation
                                                                                                                        in
                                                                                                                        the
                                                                                                                        delivery
                                                                                                                        of
                                                                                                                        our
                                                                                                                        Services
                                                                                                                        and
                                                                                                                        to
                                                                                                                        respond
                                                                                                                        to
                                                                                                                        your
                                                                                                                        inquiries.
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        fontSize: 15,
                                                                                                                        color:
                                                                                                                            'rgb(89, 89, 89)',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    <span className="body-text-policy">
                                                                                                                        <span
                                                                                                                            style={{
                                                                                                                                fontSize: 15,
                                                                                                                                color:
                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                            }}
                                                                                                                        >
                                                                                                                            <span
                                                                                                                                style={{
                                                                                                                                    fontSize: 15,
                                                                                                                                    color:
                                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                                }}
                                                                                                                            >
                                                                                                                                <span className="body-text-policy">
                                                                                                                                    <span
                                                                                                                                        style={{
                                                                                                                                            color:
                                                                                                                                                'rgb(89, 89, 89)',
                                                                                                                                            fontSize: 15,
                                                                                                                                        }}
                                                                                                                                    >
                                                                                                                                        <span className="body-text-policy">
                                                                                                                                            <span
                                                                                                                                                style={{
                                                                                                                                                    color:
                                                                                                                                                        'rgb(89, 89, 89)',
                                                                                                                                                    fontSize: 15,
                                                                                                                                                }}
                                                                                                                                            >
                                                                                                                                                <span className="body-text-policy">
                                                                                                                                                    <span
                                                                                                                                                        style={{
                                                                                                                                                            fontSize: 15,
                                                                                                                                                        }}
                                                                                                                                                    >
                                                                                                                                                        <span
                                                                                                                                                            style={{
                                                                                                                                                                color:
                                                                                                                                                                    'rgb(89, 89, 89)',
                                                                                                                                                            }}
                                                                                                                                                        >
                                                                                                                                                            <span className="body-text-policy"></span>
                                                                                                                                                        </span>
                                                                                                                                                    </span>
                                                                                                                                                </span>
                                                                                                                                            </span>
                                                                                                                                        </span>
                                                                                                                                    </span>
                                                                                                                                </span>
                                                                                                                            </span>
                                                                                                                        </span>
                                                                                                                    </span>
                                                                                                                </span>
                                                                                                            </span>
                                                                                                        </li>
                                                                                                    </ul> */}
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <strong>
                                          How do we use and share your personal
                                          information?
                                        </strong>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        More information about our data
                                        collection and sharing practices can be
                                        found in this privacy notice.
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          You may contact us{" "}
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15,
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              by email at{" "}
                                              <a
                                                href={`mailto:privacy@domecompass.com`}
                                                target={"_blank"}
                                                rel={"noreferrer noopener"}
                                                className="text-2xl link-policy"
                                              >
                                                privacy@domecompass.com
                                              </a>
                                              ,{" "}
                                            </span>
                                            <span className="body-text-policy">
                                              by visiting{" "}
                                              <a
                                                href="https://domecompass.com/contact-us"
                                                target="_blank"
                                                className="link-policy"
                                                rel="noreferrer"
                                              >
                                                https://domecompass.com/contact-us
                                              </a>
                                              ,{" "}
                                              <span className="body-text-policy" />
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                    <span className="body-text-policy">
                                      or by referring to the contact details at
                                      the bottom of this document.
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          If you are using an authorized agent
                                          to exercise your right to opt-out we
                                          may deny a request if the authorized
                                          agent does not submit proof that they
                                          have been validly authorized to act on
                                          your behalf.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <strong>
                                            Will your information be shared with
                                            anyone else?
                                          </strong>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          We may disclose your personal
                                          information with our service providers
                                          pursuant to a written contract between
                                          us and each service provider. Each
                                          service provider is a for-profit
                                          entity that processes the information
                                          on our behalf.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          We may use your personal information
                                          for our own business purposes, such as
                                          for undertaking internal research for
                                          technological development and
                                          demonstration. This is not considered
                                          to be &quot;selling&quot; of your
                                          personal data.
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15,
                                            }}
                                          >
                                            <span className="body-text-policy"></span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          Dome Compass, Inc.
                                        </span>
                                        <span className="body-text-policy">
                                          &nbsp;has not disclosed or sold any
                                          personal information to third parties
                                          for a business or commercial purpose
                                          in the preceding 12 months.{" "}
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span className="body-text-policy">
                                                Dome Compass, Inc.
                                              </span>
                                            </span>
                                          </span>{" "}
                                          will not sell personal information in
                                          the future belonging to website
                                          visitors, users and other consumers.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <strong>
                                            Your rights with respect to your
                                            personal data
                                          </strong>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <u>
                                            Right to request deletion of the
                                            data - Request to delete
                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          You can ask for the deletion of your
                                          personal information. If you ask us to
                                          delete your personal information, we
                                          will respect your request and delete
                                          your personal information, subject to
                                          certain exceptions provided by law,
                                          such as (but not limited to) the
                                          exercise by another consumer of his or
                                          her right to free speech, our
                                          compliance requirements resulting from
                                          a legal obligation or any processing
                                          that may be required to protect
                                          against illegal activities.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        <u>
                                          Right to be informed - Request to know
                                        </u>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        Depending on the circumstances, you have
                                        a right to know:
                                      </span>
                                    </span>
                                  </div>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          whether we collect and use your
                                          personal information;
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          the categories of personal information
                                          that we collect;
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          the purposes for which the collected
                                          personal information is used;
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          whether we sell your personal
                                          information to third parties;
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          the categories of personal information
                                          that we sold or disclosed for a
                                          business purpose;
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          the categories of third parties to
                                          whom the personal information was sold
                                          or disclosed for a business purpose;
                                          and
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          the business or commercial purpose for
                                          collecting or selling personal
                                          information.
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          In accordance with applicable law, we
                                          are not obligated to provide or delete
                                          consumer information that is
                                          de-identified in response to a
                                          consumer request or to re-identify
                                          individual data to verify a consumer
                                          request.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <u>
                                            Right to Non-Discrimination for the
                                            Exercise of a Consumer’s Privacy
                                            Rights
                                          </u>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          We will not discriminate against you
                                          if you exercise your privacy rights.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <u>Verification process</u>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          Upon receiving your request, we will
                                          need to verify your identity to
                                          determine you are the same person
                                          about whom we have the information in
                                          our system. These verification efforts
                                          require us to ask you to provide
                                          information so that we can match it
                                          with information you have previously
                                          provided us. For instance, depending
                                          on the type of request you submit, we
                                          may ask you to provide certain
                                          information so that we can match the
                                          information you provide with the
                                          information we already have on file,
                                          or we may contact you through a
                                          communication method (e.g. phone or
                                          email) that you have previously
                                          provided to us. We may also use other
                                          verification methods as the
                                          circumstances dictate.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          We will only use personal information
                                          provided in your request to verify
                                          your identity or authority to make the
                                          request. To the extent possible, we
                                          will avoid requesting additional
                                          information from you for the purposes
                                          of verification. If, however, we
                                          cannot verify your identity from the
                                          information already maintained by us,
                                          we may request that you provide
                                          additional information for the
                                          purposes of verifying your identity,
                                          and for security or fraud-prevention
                                          purposes. We will delete such
                                          additionally provided information as
                                          soon as we finish verifying you.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <u>Other privacy rights</u>
                                        </span>
                                      </span>
                                    </span>
                                  </div>

                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            you may object to the processing of
                                            your personal data
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            you may request correction of your
                                            personal data if it is incorrect or
                                            no longer relevant, or ask to
                                            restrict the processing of the data
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            you can designate an authorized
                                            agent to make a request under the
                                            CCPA on your behalf. We may deny a
                                            request from an authorized agent
                                            that does not submit proof that they
                                            have been validly authorized to act
                                            on your behalf in accordance with
                                            the CCPA.
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>

                                  <ul>
                                    <li
                                      style={{
                                        lineHeight: "1.5",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            you may request to opt-out from
                                            future selling of your personal
                                            information to third parties. Upon
                                            receiving a request to opt-out, we
                                            will act upon the request as soon as
                                            feasibly possible, but no later than
                                            15 days from the date of the request
                                            submission.
                                          </span>
                                        </span>
                                      </span>
                                    </li>
                                  </ul>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          To exercise these rights, you can
                                          contact us&nbsp;
                                        </span>
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                            fontSize: 15,
                                          }}
                                        >
                                          <span className="body-text-policy">
                                            <span
                                              style={{
                                                color: "rgb(89, 89, 89)",
                                                fontSize: 15,
                                              }}
                                            >
                                              <span className="body-text-policy">
                                                by email at{" "}
                                                <a
                                                  href={`mailto:privacy@domecompass.com`}
                                                  target={"_blank"}
                                                  rel={"noreferrer noopener"}
                                                  className="text-2xl link-policy"
                                                >
                                                  privacy@domecompass.com
                                                </a>
                                                ,{" "}
                                              </span>
                                              <span className="body-text-policy">
                                                by visiting{" "}
                                                <a
                                                  href="https://domecompass.com/contact-us"
                                                  target="_blank"
                                                  className="link-policy"
                                                  rel="noreferrer"
                                                >
                                                  https://domecompass.com/contact-us
                                                </a>
                                                ,{" "}
                                                <span className="body-text-policy" />
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                    <span className="body-text-policy">
                                      or by referring to the contact details at
                                      the bottom of this document. If you have a
                                      complaint about how we handle your data,
                                      we would like to hear from you.
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    id="policyupdates"
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(127, 127, 127)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15,
                                        }}
                                      >
                                        <span
                                          style={{
                                            fontSize: 15,
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              id="control"
                                              style={{
                                                color: "rgb(0, 0, 0)",
                                              }}
                                            >
                                              <strong>
                                                <span className="heading-one-policy">
                                                  10. DO WE MAKE UPDATES TO THIS
                                                  NOTICE?
                                                </span>
                                              </strong>
                                              &nbsp;
                                            </span>
                                            &nbsp;
                                          </span>
                                          &nbsp;
                                        </span>
                                        &nbsp;
                                      </span>
                                      &nbsp;
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <em>
                                      <br />
                                    </em>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          <em>
                                            <strong>In Short:&nbsp;</strong>{" "}
                                            Yes, we will update this notice as
                                            necessary to stay compliant with
                                            relevant laws.
                                          </em>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          We may update this privacy notice from
                                          time to time. The updated version will
                                          be indicated by an updated
                                          &quot;Revised&quot; date and the
                                          updated version will be effective as
                                          soon as it is accessible. If we make
                                          material changes to this privacy
                                          notice, we may notify you either by
                                          prominently posting a notice of such
                                          changes or by directly sending you a
                                          notification. We encourage you to
                                          review this privacy notice frequently
                                          to be informed of how we are
                                          protecting your information.
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    id="contact"
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(127, 127, 127)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span
                                          id="control"
                                          style={{
                                            color: "rgb(0, 0, 0)",
                                          }}
                                        >
                                          <strong>
                                            <span className="heading-one-policy">
                                              11. HOW CAN YOU CONTACT US ABOUT
                                              THIS NOTICE?
                                            </span>
                                          </strong>
                                          &nbsp;
                                        </span>
                                        &nbsp;
                                      </span>
                                      &nbsp; &nbsp; &nbsp;
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          If you have questions or comments
                                          about this notice, you may{" "}
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                              fontSize: 15,
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              email us at{" "}
                                              <a
                                                href={`mailto:privacy@domecompass.com`}
                                                target={"_blank"}
                                                rel={"noreferrer noopener"}
                                                className="text-2xl link-policy"
                                              >
                                                privacy@domecompass.com
                                              </a>
                                            </span>
                                          </span>
                                          <span
                                            style={{
                                              fontSize: 15,
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span
                                              style={{
                                                fontSize: 15,
                                                color: "rgb(89, 89, 89)",
                                              }}
                                            >
                                              <span className="body-text-policy">
                                                &nbsp;or by post to:
                                              </span>
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span className="body-text-policy">
                                      <span
                                        style={{
                                          fontSize: 15,
                                        }}
                                      >
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              color: "rgb(89, 89, 89)",
                                            }}
                                          >
                                            <span className="body-text-policy">
                                              Dome Compass, Inc.
                                            </span>
                                          </span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        700 Pennsylvania Ave. SE
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      <span className="body-text-policy">
                                        Washington
                                        <span
                                          style={{
                                            color: "rgb(89, 89, 89)",
                                          }}
                                        >
                                          <span
                                            style={{
                                              fontSize: 15,
                                            }}
                                          >
                                            , DC 20003
                                          </span>
                                          <span
                                            style={{
                                              fontSize: 15,
                                            }}
                                          ></span>
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      className="body-text-policy"
                                      style={{
                                        fontSize: 15,
                                      }}
                                    >
                                      United States
                                    </span>
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    id="request"
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        color: "rgb(127, 127, 127)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          color: "rgb(89, 89, 89)",
                                          fontSize: 15,
                                        }}
                                      >
                                        <span
                                          id="control"
                                          style={{
                                            color: "rgb(0, 0, 0)",
                                          }}
                                        >
                                          <strong>
                                            <span className="heading-one-policy">
                                              12. HOW CAN YOU REVIEW, UPDATE, OR
                                              DELETE THE DATA WE COLLECT FROM
                                              YOU?
                                            </span>
                                          </strong>
                                          &nbsp;
                                        </span>
                                        &nbsp;
                                      </span>
                                      &nbsp;
                                    </span>
                                    &nbsp; &nbsp;
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <br />
                                  </div>
                                  <div
                                    style={{
                                      lineHeight: "1.5",
                                    }}
                                  >
                                    <span
                                      style={{
                                        fontSize: 15,
                                        color: "rgb(89, 89, 89)",
                                      }}
                                    >
                                      <span
                                        style={{
                                          fontSize: 15,
                                          color: "rgb(89, 89, 89)",
                                        }}
                                      >
                                        <span className="body-text-policy">
                                          Based on the applicable laws of your
                                          country, you may have the right to
                                          request access to the personal
                                          information we collect from you,
                                          change that information, or delete it
                                          in some circumstances. To request to
                                          review, update, or delete your
                                          personal information, please{" "}
                                        </span>
                                        <span className="body-text-policy">
                                          visit:{" "}
                                          <a
                                            href="https://domecompass.com/contact-us"
                                            target="_blank"
                                            className="link-policy"
                                            rel="noreferrer"
                                          >
                                            https://domecompass.com/contact-us
                                          </a>
                                        </span>
                                      </span>
                                      <span className="body-text-policy">
                                        .
                                      </span>
                                    </span>
                                  </div>
                                </div>
                                <div
                                  style={{
                                    color: "#595959",
                                    fontSize: 14,
                                    fontFamily: "Arial",
                                    paddingTop: 16,
                                  }}
                                >
                                  This privacy policy was created using
                                  Termly&apos; s{" "}
                                  <a
                                    className="light-blue-color-link"
                                    href="https://termly.io/products/privacy-policy-generator/?ftseo"
                                  >
                                    Privacy Policy Generator
                                  </a>
                                  .
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>
      </section>
    </main>
  )
}

export default DomePrivacyPolicy
